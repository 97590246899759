import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import enTranslations from './locales/en.json';
import esTranslations from './locales/es.json';
import frTranslations from './locales/fr.json';

// Get the stored language preference from sessionStorage instead of localStorage
const storedLanguage = sessionStorage.getItem('selectedLanguage') || 'en';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enTranslations
      },
      es: {
        translation: esTranslations
      },
      fr: {
        translation: frTranslations
      }
    },
    lng: storedLanguage, // Use stored language
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    },
    detection: {
      order: ['sessionStorage', 'navigator'],
      lookupSessionStorage: 'selectedLanguage',
      caches: ['sessionStorage']
    }
  });

// Add a language change listener to persist the selection in sessionStorage
i18n.on('languageChanged', (lng) => {
  sessionStorage.setItem('selectedLanguage', lng);
});

export default i18n;