import React, { useState, Suspense, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./styles/index.css"; // Ensure styles are applied
import { FormProvider } from "./context/FormContext";
import { LocationProvider } from "./context/LocationContext";
import { DataProvider } from "./context/DataContext";
import { SessionProvider } from "./context/SessionContext";
import ScrollToTop from "./components/ScrollToTop";
import SessionTimeout from "./components/SessionTimeout";
import LoadingOverlayCommon from "./components/LoadingOverlayCommon";
import routesLogo from "./components/assets/routeslogo.png";
// Lazy load all page components
const CarRentalHome = React.lazy(() => import("./components/CarRentalHome"));
const Locations = React.lazy(() => import("./pages/Locations"));
const Reservation = React.lazy(() => import("./pages/Reservation"));
const Contact = React.lazy(() => import("./pages/Contact"));
const Vehicle = React.lazy(() => import("./pages/Vehicle"));
const Checkout = React.lazy(() => import("./pages/Checkout"));
const ReservationConfirmed = React.lazy(() => import("./pages/ReservationConfirmed"));
const RentalPolicy = React.lazy(() => import("./pages/RentalPolicy"));
const TravelAgent = React.lazy(() => import("./pages/TravelAgent"));
const SmsPolicy = React.lazy(() => import("./pages/SmsPolicy"));
const SmsAlerts = React.lazy(() => import("./pages/SmsAlerts"));
const ShuttleRequestTracking = React.lazy(() => import('./pages/ShuttleRequestTracking'));
const Offers = React.lazy(() => import('./pages/Offers'));
const Affiliate = React.lazy(() => import("./pages/Affiliate"));
const Seohomepage = React.lazy(() => import("./pages/rental-cars/rental-cars"));
const VehicleDetails = React.lazy(() => import("./pages/rental-cars/VehicleDetails"));
function App() {
  const [loadComponents, setLoadComponents] = useState(false);

  useEffect(() => {
    // Load components after the landing page is rendered
    setLoadComponents(true);
  }, []);
  return (
    <DataProvider>
      <LocationProvider>
        <FormProvider>
          <Router>
            <SessionProvider>
              <ScrollToTop />
              <SessionTimeout />
              <Suspense fallback={
                <div className="min-h-screen flex items-center justify-center bg-background relative">
                <img src={routesLogo} alt="Logo" className="absolute inset-0 m-auto h-6 w-14 z-10" />
                <div className="animate-spin rounded-full h-20 w-20 border-t-2 border-b-2 border-primary absolute" style={{ zIndex: 0 }} />
              </div>
              }>
                <Routes>
                  <Route path="/" element={<CarRentalHome />} />
                  <Route path="/locations" element={<Locations />} />
                  <Route path="/reservation" element={<Reservation />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="/vehicle" element={<Vehicle />} />
                  {loadComponents && (
                    <><Route path="/checkout" element={<Checkout />} /></>
                  )}
                  <Route path="/ReservationConfirmed" element={<ReservationConfirmed />} />
                  <Route path="/rentalpolicy" element={<RentalPolicy />} />
                  <Route path="/TravelAgent" element={<TravelAgent />} />
                  <Route path="/Affiliates" element={<Affiliate />} />
                  <Route path="/rental-cars" element={<Seohomepage />} />
                  <Route path="/rental-cars/:vehicleId" element={<VehicleDetails />} />
                  <Route path="/SmsPolicy" element={<SmsPolicy />} />
                  <Route path="/SmsAlerts" element={<SmsAlerts />} />
                  <Route path="/ShuttleRequestTracking" element={<ShuttleRequestTracking />} />
                  <Route path="/offers" element={<Offers />} />
                  <Route path="/vehicle/SkyScanner" element={<Vehicle />} />
                </Routes>
              </Suspense>
            </SessionProvider>
          </Router>
        </FormProvider>
      </LocationProvider>
    </DataProvider>
  );
}

export default App;
