import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_URL; //"http://localhost:5000";// Replace with your actual API base URL

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Location endpoints
export const getLocations = async (searchTerm = '') => {
  try {
    const response = await api.get('/get_country_city_airport_name');
    return response.data;
  } catch (error) {
    console.error('Error fetching locations:', error);
    return [];
  }
};

// Google Reviews endpoint (keeping the original endpoint)
export const getGoogleReviews = async () => {
  try {
    const response = await api.get('/get_selected_review_for_rw');
    return response.data;
  } catch (error) {
    console.error('Error fetching Google reviews:', error);
    return {
      results: [],
      average_rating: 0,
      total_reviews: 0
    };
  }
};

// Vehicle rates endpoint
export const getVehicleRates = async (searchData) => {
  try {

    // Convert 12-hour time to 24-hour format
    const convertTo24Hour = (time12h) => {
      const [time, modifier] = time12h.split(' ');
      let [hours, minutes] = time.split(':');
      // Convert back to string and ensure 2 digits
      const hoursStr = hours.toString().padStart(2, '0');
      return `${hoursStr}:${minutes}`;
    };

    // Format the complete datetime string
    function formatDateTime(date, time) {
      // const isoString = date.toISOString();
      // const datePart = isoString.split('T')[0];
      // new addition start
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
      const day = String(date.getDate()).padStart(2, '0');
      const datePart = `${year}-${month}-${day}`
  // new addition ends

      const timePart = convertTo24Hour(time);
      return `${datePart}T${timePart}:00.000`; // Removed 'Z'
    }

    // Get location ID from the selected location
    const locationId = searchData.locationId || "690337-ZYZZXZXs"; // Default fallback

    const response = await api.post('/get_rate_routes_new_website', {
      account_id: locationId.split("-")[0],
      pickup_location_id: locationId,
      dropoff_location_id: searchData.dropoff_location_id || locationId, // Same as pickup location
      pickup: formatDateTime(searchData.pickupDate, searchData.pickupTime),
      dropoff: formatDateTime(searchData.dropoffDate, searchData.dropoffTime),
      ota_code: "329836",
      rate_code: "",
      driver_country: "Canada",
      pickup_country: "Canada",
      pickup_time: convertTo24Hour(searchData.pickupTime),
      dropoff_time: convertTo24Hour(searchData.dropoffTime),
      promo_code: searchData.promoCode ? searchData.promoCode : ""
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching vehicle rates:', error);
    throw error;
  }
};

export const getVehicleRatesMobile = async (searchData) => {
  try {
   
    // Convert 12-hour time to 24-hour format
    // const convertTo24Hour = (time12h) => {
    //   const [time, modifier] = time12h.split(' ');
    //   let [hours, minutes] = time.split(':');

    //   // Convert hours to number for calculations
    //   hours = parseInt(hours, 10);

    //   if (hours === 12) {
    //     hours = modifier === 'PM' ? 12 : 0;
    //   } else if (modifier === 'PM') {
    //     hours = hours + 12;
    //   }

    //   // Convert back to string and ensure 2 digits
    //   const hoursStr = hours.toString().padStart(2, '0');
    //   return `${hoursStr}:${minutes}`;
    // };

    // Format the complete datetime string
    // function formatDateTime(date, time) {
    //   console.log("cp1", date, time);
    //   const isoString = date.toISOString();
    //   console.log("cp1.2");
    //   const datePart = isoString.split('T')[0];
    //   const timePart = convertTo24Hour(time);
    //   console.log("cp2");
    //   return `${datePart}T${timePart}:00.000`; // Removed 'Z'
    // }

    // Get location ID from the selected location
    const locationId = searchData.locationId || "690337-ZYZZXZXs"; // Default fallback

    const response = await api.post('/get_rate_routes_new_website', {
      account_id: locationId.split("-")[0],
      pickup_location_id: locationId,
      dropoff_location_id: searchData.dropoff_location_id || locationId, // Same as pickup location
      pickup: searchData.pickupDate+":00.000",
      dropoff: searchData.dropoffDate+":00.000",
      ota_code: "329836",
      rate_code: "",
      driver_country: "Canada",
      pickup_country: "Canada",
      pickup_time: searchData.pickupTime,
      dropoff_time: searchData.dropoffTime,
      promo_code: searchData.promoCode ? searchData.promoCode : ""
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching vehicle rates:', error);
    throw error;
  }
};

export const getFleet = () => api.get('/fleet');
export const makeReservation = (data) => api.post('/reservations', data);
export const getTravelAgentInfo = () => api.get('/travel-agent');
export const submitContactForm = (data) => api.post('/contact', data);

export const getOptionalServices = async (searchParams) => {
  try {
    // Format dates to match required format "YYYY-MM-DDTHH:mm:ss.000"
    const formatDateTime = (date) => {
      if (!date) return null;
      const dateObj = new Date(date);
      return `${dateObj.getFullYear()}-${String(dateObj.getMonth() + 1).padStart(2, '0')}-${String(dateObj.getDate()).padStart(2, '0')}T11:36:52.000`;
    };

    const payload = {
      location_id: searchParams.selectedLocationId,
      drop_location_id: searchParams.dropLocationId || searchParams.selectedLocationId,
      country: searchParams.country || "Canada",
      car_class: searchParams.carClass,
      pickup: formatDateTime(searchParams.pickupDate),
      dropoff: formatDateTime(searchParams.dropoffDate),
      pickup_time: searchParams.pickupTime || "11:00",
      dropoff_time: searchParams.dropoffTime || "11:00"
    };

    const response = await api.post('/get_optional_services', payload);
    return response.data;
  } catch (error) {
    console.error('Error fetching optional services:', error);
    throw error;
  }
};

export const processPayment = async (item) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/new_routes_website_api_add_reservation`,
      item
    );

    // If successful reservation
    if (response.data.success === "1" && response.data.success_text === "success") {
      const rezNumber = response.data.rez_number;
      
      // Store user IP details
      try {
        const ipResponse = await axios.get("https://ipapi.co/json");
        const userIpDetails = ipResponse.data;
        
        const ipData = {
          reservation_number: rezNumber,
          user_ip_address: userIpDetails.ip,
          user_city: userIpDetails.city,
          user_region: userIpDetails.region,
          user_country_name: userIpDetails.country_name,
          user_latitude: userIpDetails.latitude,
          user_longitude: userIpDetails.longitude
        };

        await axios.post(
          `${process.env.REACT_APP_API_URL}/new_routes_website_api_store_user_ip_detail`,
          ipData
        );
      } catch (ipError) {
        console.error("Error storing IP details:", ipError);
        // Continue with the main flow even if IP storage fails
      }
    }
    
    // Return the response data regardless of success/failure
    return response.data;
  } catch (error) {
    console.error('Payment processing error:', error);
    // Return a formatted error response
    return {
      success: "0",
      success_text: error.message || "Payment processing failed",
    };
  }
};

// Update the getShuttleInstructions function to use axios
export const getShuttleInstructions = async (locationId) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/srt_new_rw_get_shuttle_instructions/${locationId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching shuttle instructions:', error);
    throw error;
  }
};


export const getRentalPolicies = async (locationCode, language) => {
  try {
    // Use different endpoint for French and Spanish languages
    const endpoint = language === 'fr' 
      ? '/routes_website_french_policies' :
      language === 'es' ?
      '/routes_website_spanish_policies' : 
      '/new_routes_website_api_all_policies';
      
    const response = await fetch(`${process.env.REACT_APP_API_URL}${endpoint}/${locationCode}`);
    if (!response.ok) {
      throw new Error('Failed to fetch rental policies');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching rental policies:', error);
    return [];
  }
};
// this function to getVehicleDeposit
export const getVehicleDeposit = async (locationId, car_class) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/new_routes_website_api_get_vehicle_deposit`, 
      {locationId: locationId,  car_class: car_class});
    return response.data;
  } catch (error) {
    console.error('Error fetching vehicle deposit:', error);
    throw error;
  }
};

export const getReservationDetails = async (reservationNumber, contactNumber) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/new_routes_website_api_get_reservation`, {
      rez: reservationNumber,
      phone: contactNumber
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};


export const processSaveConverge = async (status,msg) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/save_converge_lightbox_log`, {
      status: status,
      msg: msg
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const processPaymentConvergeVerify = async (item) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/new_routes_website_api_add_reservation_converge_verify`,
      item
    );
    
    // Return the response data regardless of success/failure
    return response.data;
  } catch (error) {
    console.error('Payment processing error:', error);
    // Return a formatted error response
    return {
      success: "0",
      success_text: error.message || "Payment processing failed",
    };
  }
};

export default api;
