import React from 'react';
import { useSession } from '../context/SessionContext';
import { useTranslation } from 'react-i18next';
import '../styles/SessionTimeout.css';

const SessionTimeout = () => {
  const { showTimeoutModal, handleRedirectHome } = useSession();
  const { t } = useTranslation();

  if (!showTimeoutModal) return null;

  return (
    <div className="session-timeout-overlay">
      <div className="session-timeout-modal">
        <h2>{t('sessionTimeout.title')}</h2>
        <p>{t('sessionTimeout.message')}</p>
        <button onClick={handleRedirectHome}>{t('sessionTimeout.button')}</button>
      </div>
    </div>
  );
};

export default SessionTimeout; 